import { useQuery } from '@tanstack/react-query'
import { useTokenContext } from '../context/TokenProvider'
import useBaseFetch from '../hooks/useBaseFetch'
import { Assistant } from '../types/assistant'
import { LaunchButton } from '../types/launch-button'

export const QK_ASSISTANT = '/assistants'
export const QK_ASSISTANT_BUTTON = '/assistants/button'
export const QK_COURSE_STATUS = '/courses/:course_id/status'

export const useGetAssistantById = (id: string) => {
  const fetch = useBaseFetch()
  const { token } = useTokenContext()

  return useQuery<Assistant>({
    queryKey: [QK_ASSISTANT, id],
    queryFn: () => fetch.get(QK_ASSISTANT),
    enabled: !!id && !!token,
    staleTime: Infinity,
  })
}

export const useGetAssistantConfig = (id: string) => {
  const fetch = useBaseFetch()

  return useQuery<LaunchButton>({
    queryKey: [QK_ASSISTANT_BUTTON, id],
    queryFn: () => fetch.get(QK_ASSISTANT_BUTTON),
    enabled: !!id,
    staleTime: Infinity,
  })
}

export const useGetCourseStatus = (id?: string) => {
  const fetch = useBaseFetch()

  return useQuery<{ status: 'ready' | 'processing' | 'failed' | 'unknown'; is_course_supported: boolean }>({
    queryKey: [QK_COURSE_STATUS, id],
    queryFn: () => fetch.post(`/courses/${id}/status`),
    enabled: !!id,
    staleTime: Infinity,
    refetchInterval: ({ state }) =>
      state.data && state.data.is_course_supported && state.data.status !== 'ready' ? 5000 : false,
  })
}
