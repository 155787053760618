import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react'
import { useConversationContext } from './ConversationProvider'
import { log } from '../utils/log'

const useContextHook = () => {
  const { assistantId, userData, shouldDoSignIn } = useConversationContext()
  log(
    '[TokenProvider.tsx] [useContextHook] Initial state:',
    'assistantId:',
    assistantId,
    'userData:',
    JSON.stringify(userData),
    'shouldDoSignIn:',
    shouldDoSignIn,
  )
  const [token, doSetToken] = useState<string>()

  useEffect(() => {
    log(
      '[TokenProvider.tsx] [useContextHook] [useEffect] assistantId changed:',
      JSON.stringify({
        newAssistantId: assistantId,
        currentToken: token,
      }),
    )

    try {
      const local = window.localStorage.getItem('token')
      if (assistantId) {
        const parsedToken = local ? (JSON.parse(local)?.[assistantId] as string) || '' : ''
        log(
          '[TokenProvider.tsx] [useContextHook] [useEffect] Loading token from localStorage:',
          JSON.stringify({
            hasLocalStorage: !!local,
            foundTokenForAssistant: !!parsedToken,
          }),
        )
        doSetToken(parsedToken)
      }
    } catch (e: any) {
      doSetToken('')
    }
  }, [assistantId])

  const setToken = (val: string) => {
    log('[TokenProvider.tsx] [useContextHook] [setToken] Setting token for assistantId:', assistantId)
    doSetToken(val)

    try {
      const currentTokens = window.localStorage.getItem('token')
      const updatedTokens = currentTokens
        ? { ...JSON.parse(currentTokens), [assistantId]: val }
        : { [assistantId]: val }
      window.localStorage.setItem('token', JSON.stringify(updatedTokens))
    } catch (e: any) {}
  }

  return {
    token,
    setToken,
  }
}

type StorageHook = ReturnType<typeof useContextHook>

const TokenContext = createContext<StorageHook>({} as any)

type Props = {
  children?: ReactNode
}

const TokenProvider: FC<Props> = ({ children }) => {
  const props = useContextHook()

  useEffect(() => {
    log(
      '[TokenProvider.tsx] [TokenProvider] [useEffect] token state changed:',
      JSON.stringify({
        hasToken: props.token !== undefined,
        tokenValue: props.token === undefined ? 'undefined' : props.token === null ? 'null' : 'has-value',
      }),
    )
  }, [props.token])

  return <TokenContext.Provider value={props}>{children}</TokenContext.Provider>
}

export const useTokenContext = () => useContext(TokenContext)

export default TokenProvider
